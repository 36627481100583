import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate } from 'react-router-dom';
import routes, {
    ROUTE_ADHESION_PAIEMENT,
    ROUTE_CONNEXION,
    ROUTE_CONNEXION_APRES_VALIDATION_EMAIL,
    ROUTE_FIN_ADHESION,
    ROUTE_HOME,
    ROUTE_INSCRIPTION,
    ROUTE_NOUVEAU_MDP,
    ROUTE_REINITIALISATION_MDP,
} from '_core/router/routes';
import HeaderFooterControl from './HeaderFooterControl';
import { useAuth, CONNECTED } from '_core/authentification/service/AuthService';
import { storageRead } from '_common/service/StorageService';
import ToasterProvider from '_common/component/toaster/ToasterProvider';
import AuthentificationConnexion from '_core/authentification/component/connexion/AuthentificationConnexion';
import Inscription from '_core/authentification/component/inscription/AuthentificationInscription';
import AuthentificationResetForgottenPassword from '_core/authentification/component/mot-de-passe/AuthentificationResetForgottenPassword';
import AuthentificationSetPassword from '_core/authentification/component/mot-de-passe/AuthentificationSetPassword';
import AdhesionEnding from '_core/adhesion/component/AdhesionEnding';
import AdhesionPayement from '_core/adhesion/component/AdhesionPayement';
import AccessibilityGlobalMenu from './accessibility-global-menu/AccessibilityGlobalMenu';
import {
    initMessagerieListener,
    initNotificationListener,
} from './header/service/MessagerieAndNotificationRedDotService';
import Style from './App.module.css';
import { setBearerToken } from '_common/service/ApiService';
import { SentryRoutes } from 'index';

function App() {
    const [user, cnxState] = useAuth();
    const isConnected = user && cnxState === CONNECTED;
    const token = storageRead('token');
    if (token) {
        setBearerToken(token.token);
    }

    useEffect(() => {
        initMessagerieListener();
        initNotificationListener();
    }, []);

    return (
        <>
            {isConnected && <AccessibilityGlobalMenu />}
            <div className={Style.App}>
                <Router>
                    <HeaderFooterControl>
                        <SentryRoutes>
                            {routes.map((route) =>
                                route.secured && isConnected && token ? (
                                    <Route
                                        key={route.key}
                                        path={route.path}
                                        element={
                                            <Suspense fallback={<div></div>}>
                                                <main role='main'>{route.element}</main>
                                            </Suspense>
                                        }
                                    />
                                ) : (
                                    <>
                                        {route.path === ROUTE_INSCRIPTION && (
                                            <Route path={ROUTE_INSCRIPTION} element={<Inscription />} />
                                        )}
                                        {route.path === ROUTE_REINITIALISATION_MDP && (
                                            <Route
                                                path={ROUTE_REINITIALISATION_MDP}
                                                element={<AuthentificationResetForgottenPassword />}
                                            />
                                        )}
                                        {route.path === ROUTE_NOUVEAU_MDP && (
                                            <Route path={ROUTE_NOUVEAU_MDP} element={<AuthentificationSetPassword />} />
                                        )}
                                        {route.path === ROUTE_FIN_ADHESION && isConnected && token && (
                                            <Route path={ROUTE_FIN_ADHESION} element={<AdhesionEnding />} />
                                        )}
                                        {route.path === ROUTE_ADHESION_PAIEMENT && (
                                            <Route path={ROUTE_ADHESION_PAIEMENT} element={<AdhesionPayement />} />
                                        )}
                                        {route.path === ROUTE_HOME && (
                                            <Route path={ROUTE_HOME} element={<AuthentificationConnexion />} />
                                        )}
                                        {route.path === ROUTE_CONNEXION && (
                                            <Route path={ROUTE_CONNEXION} element={<AuthentificationConnexion />} />
                                        )}
                                        {route.path === ROUTE_CONNEXION_APRES_VALIDATION_EMAIL && (
                                            <Route
                                                path={ROUTE_CONNEXION_APRES_VALIDATION_EMAIL}
                                                element={<AuthentificationConnexion />}
                                            />
                                        )}
                                    </>
                                )
                            )}
                            <Route path='*' element={<Navigate to='/' />} />
                        </SentryRoutes>
                    </HeaderFooterControl>
                    <ToasterProvider />
                </Router>
            </div>
        </>
    );
}

export default App;
